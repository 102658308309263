<!--管理所属编辑-->
<template>
    <div id="deviceSettings" ref="deviceSettings">
        <a-modal title="场景设置"
            :visible="visible"
            :getContainer="() => this.$refs.deviceSettings"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            :destroyOnClose="true"
            :zIndex="1002"
            :confirmLoading="confirmLoading"
            @cancel="dialogHide"
            @ok="submitActions"
            centered class="deviceSettingsDialog"
            v-drag>
            <div class="main">
                <a-form-model
                    ref="formModelRef"
                    :model="formData"
                    :rules="formRules"
                    :label-col="formLabelCol"
                    :wrapper-col="formWrapperCol">
                    <a-form-model-item label="场景名称" prop="name">
                        <a-input v-model="formData.name" placeholder="请选择"/>
                    </a-form-model-item>
                    <a-form-model-item label="执行周期" class="executeCycleOptionsItem">
                        <a-checkbox-group class="executeCycleWrap" v-model="formData.executeCycleOptions">
                            <a-checkbox value="1" name="type">周一</a-checkbox>
                            <a-checkbox value="2" name="type">周二</a-checkbox>
                            <a-checkbox value="3" name="type">周三</a-checkbox>
                            <a-checkbox value="4" name="type">周四</a-checkbox>
                            <a-checkbox value="5" name="type">周五</a-checkbox>
                            <a-checkbox value="6" name="type">周六</a-checkbox>
                            <a-checkbox value="7" name="type">周七</a-checkbox>
                        </a-checkbox-group>
                    </a-form-model-item>
                    <a-form-model-item label="执行类型" prop="effectedInHoliday">
                        <a-radio-group v-model="formData.effectedInHoliday">
                            <a-radio :value="0">工作日</a-radio>
                            <a-radio :value="1">节假日</a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="选择时间" class="strategiesOptionsItem">
                        <div class="strategiesItem-wrap"
                            v-for="(strategiesItem, strategiesIndex) in formData.strategiesOptions"
                            :key="'strategiesItem-' + strategiesIndex">
                            <a-form-model-item :label="null"
                                :prop="'strategiesOptions.' + strategiesIndex + '.strategyTime'"
                                :rules="{ required: true, message: '请选择' }">
                                <a-time-picker
                                    :value="strategiesItem.strategyTime"
                                    format="HH:mm"
                                    valueFormat="HH:mm"
                                    @change="(timeValue, timeValueString) => strategyTimeChange(timeValue, timeValueString, strategiesItem, strategiesIndex)"/>
                            </a-form-model-item>
                            <a-form-model-item :label="null"
                                :prop="'strategiesOptions.' + strategiesIndex + '.strategyRule'"
                                :rules="{ required: true, message: '请选择' }">
                                <a-radio-group v-model="strategiesItem.strategyRule">
                                    <a-radio value="on">开启</a-radio>
                                    <a-radio value="off">关闭</a-radio>
                                </a-radio-group>
                            </a-form-model-item>
                            <a-form-model-item >
                                <a-icon
                                    class="strategiesItem-item-delete"
                                    type="close-circle"
                                    @click.stop="strategiesItemDeleteEvent(strategiesItem, strategiesIndex)"
                                />
                            </a-form-model-item>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item class="addStrategyButtonWrap"
                        prop="strategiesOptions"
                        v-bind="{wrapperCol: { span: 24, offset: 0 }}">
                        <a-button type="dashed" @click="addStrategyItemEvent">
                            <a-icon type="plus" /> 添加时间
                        </a-button>
                    </a-form-model-item>
                    <a-form-model-item label="是否生效">
                        <a-switch v-model="formData.isValid" />
                    </a-form-model-item>
                    <a-form-model-item label="描述">
                        <a-textarea
                            v-model="formData.description"
                            allow-clear placeholder="请输入"
                            :auto-size="{ minRows: 2, maxRows: 3 }"
                        />
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { some, cloneDeep } from "lodash";
import { getPointSceneDetails, postPointSceneSave } from "@/api/device";
export default {
    props: {
        siteId: {
            type: Array,
        }
    },
    data() {
        return {
            confirmLoading: false,
            visible: false,
            formLabelCol: {
                span: 4
            },
            formWrapperCol: {
                span: 20
            },
            formRules: {
                name: [
                    { required: true, message: '不能为空', trigger: 'change'}
                ],
                effectedInHoliday: [
                    { required: true, type: 'number', message: '请选择', trigger: 'change'}
                ],
                strategiesOptions: [
                    { required: true, type: 'array', message: '至少选择一项以上', trigger: 'change' }
                ]
            },
            formData: {
                // 场景名称
                name: null,
                // 选择灯光
                points: [],
                // 执行周期
                executeCycleOptions: [],
                // 执行类型
                effectedInHoliday: null,
                // 选择时间
                strategiesOptions: [],
                // 文字描述
                description: null,
                // 是否生效
                isValid: true
            }
        };
    },
    methods: {
        async dialogShow(rowId) {
            this.visible = true;
            const formData = this.formData;
            if (rowId != null) {
                this.$set(formData, 'id', rowId);
                try {
                    this.confirmLoading = true;
                    const result = await getPointSceneDetails(rowId);
                    const detailsData = result.data;
                    this.confirmLoading = false;
                    // 场景名称
                    formData.name = detailsData.name;
                    // 执行周期
                    formData.executeCycleOptions = detailsData.executeCycle !== "" ? (detailsData.executeCycle).split(',') : [];
                    // 执行类型
                    formData.effectedInHoliday = detailsData.effectedInHoliday;
                    // 选择时间
                    const strategiesDataOptions = detailsData.strategies || [];
                    const strategiesDataOptionsResult = strategiesDataOptions.reduce((prev, next) => {
                        if (next) {
                            const strategiesParamsItem = {
                                strategyTime: next.strategyTime != null ||  next.strategyTime != "" ? (next.strategyTime).substring(0, 5) : null,
                                strategyRule: next.strategyRule,
                            };
                            prev.push(strategiesParamsItem);
                        }
                        return prev;
                    }, []);
                    formData.strategiesOptions = strategiesDataOptionsResult;
                    // 文字描述
                    formData.description = detailsData.description;
                    // 是否生效
                    const isValidOptions = { 0: false, 1: true };
                    formData.isValid = isValidOptions[detailsData.isValid];
                } catch (err) {
                    this.confirmLoading = false;
                }
            }
        },
        dialogHide() {
            this.visible = false;
            const formData = this.formData;
            Object.keys(formData).forEach(keyName => {
                if (Array.isArray(formData[keyName])) {
                    formData[keyName] = [];
                } else if (['isValid'].includes(keyName) ) {
                    formData[keyName] = true;
                } else {
                    formData[keyName] = null;
                }
            });
        },
        // 提交
        submitActions() {
            this.$refs.formModelRef.validate(valid => {
                if (!valid) return false;
                const formData = this.formData;
                let cloneStrategiesOptions = Array.isArray(formData.strategiesOptions) ? cloneDeep(formData.strategiesOptions) : [];
                cloneStrategiesOptions.forEach(items => {
                    if (items) {
                        items.strategyTime = `${items.strategyTime}:00`;
                    }
                });
                let params = {
                    name: formData.name,
                    executeCycle: Array.isArray(formData.executeCycleOptions) && formData.executeCycleOptions.length > 0 ? (formData.executeCycleOptions).join(',') : null,
                    effectedInHoliday: formData.effectedInHoliday,
                    points: [],
                    strategies: cloneStrategiesOptions,
                    isValid: Number(formData.isValid),
                    description: formData.description
                };
                if (formData.id != null) {
                    params.id = formData.id;
                }
                console.log('params:', params);

                this.$confirm({
                    title: '是否确定提交?',
                    zIndex: 1003,
                    onOk: async() => {
                        try {
                            this.confirmLoading = true;
                            const result = await postPointSceneSave(params);
                            const { errorCode, msg } = result;
                            this.confirmLoading = false;
                            if (errorCode == "00") {
                                this.$message.success("提交成功");
                                this.addSceneDateDialogHideEvent();
                                if (params.id != null) {
                                    this.$emit('sceneDateDialogSubmitCallBack', 1)
                                } else {
                                    this.$emit('sceneDateDialogSubmitCallBack', 0)
                                }
                            } else if (errorCode != "00" && msg) {
                                this.$message.error(msg);
                            } else {
                                this.$message.error("删除失败, 请稍后重试~");
                            }
                        } catch (err) {
                            this.confirmLoading = false;
                        }
                    },
                    onCancel() {
                        return false;
                    }
                });
            });
        },
        // 添加时间
        addStrategyItemEvent() {
            const strategiesDataOptions = this.formData.strategiesOptions;
            const paramsItem = {
                strategyTime: null,
                strategyRule: 'on'
            }
            strategiesDataOptions.push(paramsItem);
        },
        // 删除
        strategiesItemDeleteEvent(item, index) {
            const strategiesDataOptions = this.formData.strategiesOptions;
            strategiesDataOptions.splice(index, 1);
        },
        // 选择时间
        strategyTimeChange(timeValue, timeValueString, item, index) {
            const { strategiesOptions } = this.formData;
            if (strategiesOptions.length > 1) {
                if ((timeValue && timeValue != '') && some(strategiesOptions, ['strategyTime', timeValue])) {
                    this.$message.error('已存在重复的时间');
                    return;
                }
            }
            this.$set(item, 'strategyTime', timeValue);
        }
    }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
    background-color: #7682ce;
}

div /deep/.ant-modal-title {
    color: #ffffff;
}



.button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.shut {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid rgba(12, 12, 12, 0.2);
}

</style>


<style lang="less" scoped>
::v-deep .deviceSettingsDialog {
    .ant-form-item {
        margin-bottom: 15px;
    }
    .ant-modal-body {
        max-height: 450px;
        overflow-y: auto;
    }
    .executeCycleWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
        .ant-checkbox-wrapper {
            width: 70px;
            margin: 0;
        }
    }
    .strategiesItem-wrap {
        display: flex;
        gap: 15px;
    }
    .strategiesOptionsItem  {
        margin-bottom: 0px;
    }
    .strategiesItem-item-delete {
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
    }
    .addStrategyButtonWrap .ant-form-item-control-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        button.ant-btn {
            width: 320px;
        }
    }
}
</style>