<!--管理所属编辑-->
<template>
    <div id="sceneLightSelect" ref="sceneLightSelect">
        <a-modal title="选择灯光"
            :visible="visible" width="1000px"
            :getContainer="() => this.$refs.sceneLightSelect"
            :maskClosable="false"
            :destroyOnClose="true"
            :zIndex="1003"
            :confirmLoading="loading"
            @cancel="dialogHide"
            @ok="submitActions"
            centered class="sceneLightSelectDialog"
            v-drag>
            <div class="table-header">
                <a-form-model
                    ref="formModelRef"
                    :model="formData"
                    :label-col="formLabelCol"
                    :wrapper-col="formWrapperCol">
                    <a-form-model-item label="场景名称: ">
                        <span>{{formData.name}}</span>
                    </a-form-model-item>
                    <a-form-model-item label="楼层/租户选择: ">
                        <a-select v-model="formData.region" style="width: 150px" @change="regionChange" :loading="loading">
                            <a-select-option v-for="(item, index) in fieldOptions" :key="index">{{item.name}}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-form-model>
            </div>
            <div class="main">
                <div style="margin-bottom: 16px">
                    <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="clearTableListEvent">
                        清除选择
                    </a-button>
                    <span style="margin-left: 8px">
                        <template v-if="hasSelected">
                            {{ `已选择 ${selectedRowKeys.length} 项` }}
                        </template>
                    </span>
                </div>
                <a-table
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :columns="theadList" rowKey="id"
                    :loading="loading"
                    :pagination="false"
                    :scroll="{y: '350px'}"
                    :data-source="tbodyListArr" />
            </div>
        </a-modal>
    </div>
</template>

<script>
import { getTenantList } from "@/api/door";
import { getCardList, postPointSceneSave } from "@/api/device";
import { cloneDeep } from "lodash";
export default {
    data() {
        return {
            visible: false,
            loading: false,
            formLabelCol: {
                span: 3
            },
            formWrapperCol: {
                span: 21
            },
            formData: {
                // 场景名称
                name: null,
                // 楼层/租户选择
                region: 0
            },
            // 场景数据
            rowData: {},
            // 场地
            fieldOptions: [],
            theadList: [
                { title: '设备名称', align: "center", dataIndex: "name" },
                { title: '开关状态', align: "center", dataIndex: "switchStatusName" },
            ],
            tbodyList: [],
            selectedRowKeys: []
        };
    },
    computed: {
        tbodyListArr() {
            const data = [...this.tbodyList];
            data.forEach(items => {
                const boMapOptions = items.boMap || [];
                const findItem = boMapOptions.find(items => Object.keys(items).includes('BO_InVal') ? items : null);
                items.switchStatusName = findItem.BO_InVal == 1 ? '开启' : '关闭';
            });
            return data;
        },
        hasSelected() {
            return this.selectedRowKeys.length > 0;
        }
    },
    methods: {
        dialogShow(params = {}) {
            console.log('params:', params);
            this.visible = true;
            const formData = this.formData;
            // 场景名称
            formData.name = params.name;
            this.rowData = params;
            // 灯光点默认勾选
            if (Array.isArray(params.points) && params.points.length > 0) {
                const clonePointsResult = cloneDeep(params.points);
                const pointsOptions = clonePointsResult.reduce((prev, next) => {
                    if (next) {
                        prev.push(next.id)
                    }
                    return prev;
                }, []);
                this.selectedRowKeys = pointsOptions;
            }
            this.getFieldListRequest()
        },
        dialogHide() {
            this.visible = false;
            this.fieldOptions = [];
            this.tbodyList = [];
            this.loading = false;
            this.selectedRowKeys = [];
            const formData = this.formData;
            Object.keys(formData).forEach(keyName => {
                if (Array.isArray(formData[keyName])) {
                    formData[keyName] = [];
                } else if (keyName === 'region') {
                    formData[keyName] = 0;
                } else {
                    formData[keyName] = null;
                }
            });
        },
        async getFieldListRequest() {
            try {
                const result = await getTenantList(this.$route.query.id);
                const { data } = result;
                const cloneData = cloneDeep(data);
                for (let i = cloneData.length - 1; i > -1; i--) {
                    this.fieldOptions.push({ value: cloneData[i].id, name: cloneData[i].name });
                }
                if (this.fieldOptions.length > 0) {
                    const [firstItem] = this.fieldOptions;
                    this.getCardListRequest(firstItem.value);
                }
            } catch (err) {

            }
        },
        getCardListRequest(tenantId){
            this.loading = true;
            let params = {
                siteId: this.$route.query.id,
                tenantId: tenantId,
                deviceType: 300,
                deviceKind: 2,
            };
            getCardList(params)
            .then((res) => {
                let { data } = res;
                this.tbodyList = data;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            });
        },
        regionChange(itemIndex) {
            const fieldOptions = this.fieldOptions;
            const fieldItem = fieldOptions[itemIndex];
            if (fieldItem && fieldItem.value != null) {
                this.selectedRowKeys = [];
                this.getCardListRequest(fieldItem.value);
            }
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
        },
        clearTableListEvent() {
            this.selectedRowKeys = [];
        },
        submitActions() {
            console.log('submit-params:', this.rowData, ',[selectedRowKeys]:', this.selectedRowKeys);

            const formData = this.rowData;
            // 灯光点
            const clonePointsResult = Array.isArray(this.selectedRowKeys) ? cloneDeep(this.selectedRowKeys) : [];
            const pointsResult = clonePointsResult.reduce((prev, next) => {
                if (next != null) {
                    prev.push({id: next});
                }
                return prev;
            }, []);

            // 时间段
            let cloneStrategiesOptions = Array.isArray(formData.strategies) ? cloneDeep(formData.strategies) : [];
            const strategiesResult = cloneStrategiesOptions.map(items => {
                if (items) {
                    return {
                        strategyRule: items.strategyRule,
                        strategyTime: items.strategyTime
                    }
                }
            });
            let params = {
                name: formData.name,
                executeCycle: formData.executeCycle != null ? formData.executeCycle : null,
                effectedInHoliday: formData.effectedInHoliday,
                points: pointsResult,
                strategies: strategiesResult,
                isValid: Number(formData.isValid),
                description: formData.description
            };
            if (formData.id != null) {
                params.id = formData.id;
            }
            console.log('params:', params);

            this.$confirm({
                title: '是否确定提交?',
                zIndex: 1004,
                onOk: async() => {
                    try {
                        this.loading = true;
                        const result = await postPointSceneSave(params);
                        const { errorCode, msg } = result;
                        this.loading = false;
                        if (errorCode == "00") {
                            this.$message.success("提交成功");
                            this.dialogHide();
                            this.$emit('scenePointsDialogSubmitCallBack', 0)
                        } else if (errorCode != "00" && msg) {
                            this.$message.error(msg);
                        } else {
                            this.$message.error("删除失败, 请稍后重试~");
                        }
                    } catch (err) {
                        this.loading = false;
                    }
                },
                onCancel() {
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
    background-color: #7682ce;
}

div /deep/.ant-modal-title {
    color: #ffffff;
}



.button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.shut {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid rgba(12, 12, 12, 0.2);
}

</style>


<style lang="less" scoped>
::v-deep .deviceTableDialog {
    .ant-form-item {
        margin-bottom: 15px;
    }
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 450px;
        overflow-y: auto;
    }
    .executeCycleWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
        .ant-checkbox-wrapper {
            width: 80px;
            margin: 0;
        }
    }
    .strategiesItem-wrap {
        display: flex;
        gap: 15px;
    }
    .strategiesOptionsItem  {
        margin-bottom: 0px;
    }
    .strategiesItem-item-delete {
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
    }
    .addStrategyButtonWrap .ant-form-item-control-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        button.ant-btn {
            width: 320px;
        }
    }
}
</style>