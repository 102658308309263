<!--管理所属编辑-->
<template>
    <div id="deviceTable" ref="deviceTable">
        <a-modal title="查看场景列表" width="1000px"
            :visible="visible"
            :maskClosable="false"
            :getContainer="() => this.$refs.deviceTable"
            :destroyOnClose="true"
            :footer="null"
            @cancel="cancelActions"
            centered class="deviceTableDialog"
            v-drag>
            <div class="table-header">
                <a-button type="primary" @click="addSceneDateEvent">添加</a-button>
                <a-button type="primary" @click="requestListActions">刷新</a-button>
            </div>
            <div class="table-content">
                <a-table
                    :columns="theadList"
                    :dataSource="tbodyListArr"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="{y: '350px'}"
                    :rowKey="(record) => record.id"
                >
                    <span slot="executeCycle" slot-scope="value, row">{{value | weekdaysFormart}}</span>
                    <span slot="isValid" slot-scope="value, row">
                        <a-tag v-if="value === 1" color="green">生效</a-tag>
                        <a-tag v-if="value === 0" color="volcano">失效</a-tag>
                    </span>
                    <span slot="pointsName" slot-scope="value, row">
                        <a-tooltip>
                            <template slot="title">
                                {{ value }}
                            </template>
                            <div class="multi-ellipsis">{{ value }}</div>
                        </a-tooltip>
                    </span>
                    <span slot="strategiesName" slot-scope="value, row">
                        <a-tooltip>
                            <template slot="title">
                                {{ value }}
                            </template>
                            <div class="multi-ellipsis">{{ value }}</div>
                        </a-tooltip>
                    </span>
                    <template slot="action" slot-scope="value, row">
                        <a-button type="link" size="small" @click="lightsRowDataEvent(row)">选择灯光</a-button>
                        <a-button type="link" size="small" @click="detailsRowDataEvent(row)">编辑</a-button>
                        <a-button type="link" size="small" @click="deleteRowDataEvent(row)">删除</a-button>
                    </template>
                </a-table>
            </div>
        </a-modal>
        <!-- 模式设置 -->
        <my-sceneDate-dialog ref="sceneDateDialogRefs" @sceneDateDialogSubmitCallBack="sceneDateDialogSubmitCallBack"/>
        <!-- 选择灯光 -->
        <my-scene-light-select-dialog ref="sceneLightSelectDialogRefs" @scenePointsDialogSubmitCallBack="scenePointsDialogSubmitCallBack"/>
    </div>
</template>

<script>
import { getPointSceneList, postPointSceneDelete } from "@/api/device";
import sceneDateDialog from "./sceneDateDialog"
import sceneLightSelectDialog from "./sceneLightSelectDialog"
import moment from "moment";
export default {
    components: {
        "my-sceneDate-dialog": sceneDateDialog,
        "my-scene-light-select-dialog": sceneLightSelectDialog
    },
    filters: {
        weekdaysFormart(value) {
            if (!value) return "";
            const weekdaysOptions = { 1:'周一', 2: '周二', 3:'周三', 4: '周四', 5: '周五', 6: '周六', 7: '周日'};
            const valueToArray = value.split(',');
            const valueToResult = valueToArray.map(items => weekdaysOptions[items] ? weekdaysOptions[items] : null);
            return valueToResult.filter(Boolean).join(',');
        }
    },
    data() {
        return {
            loading: false,
            visible: false,
            theadList: [
                { title: '场景名称', align: "center", dataIndex: "name" },
                { title: '执行周期', align: "center", dataIndex: "executeCycle",  scopedSlots: { customRender: 'executeCycle' } },
                { title: '执行类型', align: "center", dataIndex: "effectedInHolidayName" },
                { title: '执行时间', align: "center", dataIndex: "strategiesName", scopedSlots: { customRender: 'strategiesName' }  },
                { title: '场景描述', align: "center", dataIndex: "description" },
                { title: '已选灯光', align: "center", dataIndex: "pointsName", scopedSlots: { customRender: 'pointsName' } },
                { title: '是否有效', align: "center", dataIndex: "isValid", scopedSlots: { customRender: 'isValid' } },
                { title: '操作', align: "center", scopedSlots: { customRender: 'action' }, width: "180px" },
            ],
            tbodyList: [],
            indexFormData: {},
            pagination: {
                defaultCurrent: 1,
                pageSize: 50,
                total: 0,
                onShowSizeChange: (current, pageSize) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = pageSize;
                    this.requestListActions();
                },
                onChange: (current, size) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = size;
                    this.requestListActions();
                }
            }
        };
    },
    computed: {
        tbodyListArr() {
            const data = [...this.tbodyList];
            const effectedInHolidayOptions = { 0: "工作日", 1: "节假日" };
            data.forEach(items => {
                // 执行类型
                items.effectedInHolidayName = effectedInHolidayOptions[items.effectedInHoliday];
                // 已选灯光
                if (Array.isArray(items.points)) {
                    const pointsResult = items.points.reduce((prev, next) => {
                        if (next) {
                            prev.push(next.name);
                        }
                        return prev;
                    }, []);
                    items.pointsName = pointsResult.length > 0 ? pointsResult.join(',') : null;
                }
                // 执行时间
                if (Array.isArray(items.strategies)) {
                    const strategiesResult = items.strategies.reduce((prev, next) => {
                        if (next) {
                            const strategiesToTime = next.strategyTime;
                            const strategyRuleToName = {'on': '开启', 'off': '关闭'};
                            const itemResult = `${strategiesToTime}(${strategyRuleToName[next.strategyRule]})`;
                            prev.push(itemResult);
                        }
                        return prev;
                    }, []);
                    items.strategiesName = strategiesResult.length > 0 ? strategiesResult.join(',') : null;
                }
            });
            return data;
        }
    },
    methods: {
        dialogShow(params) {
            this.visible = true;
            this.requestListActions();
        },
        // 列表查询
        async requestListActions() {
            try {
                const params = {
                    pageNum: this.pagination.defaultCurrent,
                    pageSize: this.pagination.pageSize,
                }
                this.loading = true;
                const result = await getPointSceneList(params);
                const { list, total } = result.data;
                this.tbodyList = list;
                this.pagination.total = total;
                this.loading = false;
            } catch (err) {
                this.loading = false;
            }
        },
        // 选择灯光
        lightsRowDataEvent(rowData) {
            const sceneLightSelectDialogRefs = this.$refs.sceneLightSelectDialogRefs;
            sceneLightSelectDialogRefs.dialogShow(rowData);
        },
        // 删除按钮
        deleteRowDataEvent(row) {
            this.$confirm({
                title: "是否确定删除?",
                centered: true,
                zIndex: 1002,
                onOk: async () => {
                    try {
                        this.loading = true;
                        const params = { pointSceneId: row.id };
                        const result = await postPointSceneDelete(params);
                        const { errorCode, msg } = result;
                        if (errorCode == "00") {
                            this.$message.success("删除成功");
                            this.requestListActions();
                        } else if (errorCode != "00" && msg) {
                            this.$message.error(msg);
                        } else {
                            this.$message.error("删除失败, 请稍后重试~");
                        }
                    } catch (err) {
                        this.loading = false;
                    }

                }
            });
        },
        // 详情按钮
        detailsRowDataEvent(row) {
            const sceneDateDialogRefs = this.$refs.sceneDateDialogRefs;
            sceneDateDialogRefs.dialogShow(row.id);
        },
        cancelActions() {
            this.visible = false;
            const paginationItem = this.pagination;
            paginationItem.defaultCurrent = 1;
            paginationItem.pageSize = 50;
            paginationItem.total = 0;
            this.tbodyList = [];
            this.loading = false;
        },
        // 添加
        addSceneDateEvent() {
            const sceneDateDialogRefs = this.$refs.sceneDateDialogRefs;
            sceneDateDialogRefs.dialogShow();
        },
        addSceneDateDialogHideEvent() {
            const sceneDateDialogRefs = this.$refs.sceneDateDialogRefs;
            sceneDateDialogRefs.dialogHide();
        },
        // 场景设置策略提交
        sceneDateDialogSubmitCallBack(value) {
            if (value === 1) {
                this.pagination.defaultCurrent = 1;
                this.requestListActions();
            } else {
                this.requestListActions();
            }
        },
        // 场景设置灯光提交
        scenePointsDialogSubmitCallBack(value) {
            if (value === 1) {
                this.pagination.defaultCurrent = 1;
                this.requestListActions();
            } else {
                this.requestListActions();
            }
        }
    }
}
</script>

<style scoped>
div /deep/.ant-modal-header {
    background-color: #7682ce;
}

div /deep/.ant-modal-title {
    color: #ffffff;
}

.table-header {
    display: flex;
    gap: 10px;
}

.multi-ellipsis {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* 超出两行则以...形式隐藏 */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.shut {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid rgba(12, 12, 12, 0.2);
}

</style>


<style lang="less" scoped>
::v-deep .deviceTableDialog {
    .ant-form-item {
        margin-bottom: 15px;
    }
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 450px;
        overflow-y: auto;
    }
    .executeCycleWrap {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        overflow: hidden;
        .ant-checkbox-wrapper {
            width: 80px;
            margin: 0;
        }
    }
    .strategiesItem-wrap {
        display: flex;
        gap: 15px;
    }
    .strategiesOptionsItem  {
        margin-bottom: 0px;
    }
    .strategiesItem-item-delete {
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
    }
    .addStrategyButtonWrap .ant-form-item-control-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        button.ant-btn {
            width: 320px;
        }
    }
}
</style>