<template>
  <div id="lightCard" @click="goDetails" style="position: relative;">
    <div class="title">
      <img src="../../../../public/images/icon/116.png" />
      <span>{{cardContent.name}}</span>
    </div>
    <div class="AO_InVal">
      <span>{{aoInVal}}</span>
    </div>
    <div class="switch" @click.stop>
      <a-switch
        v-model="switchStatusValue"
        :default-checked="state"
        :disabled="disabled"
        :checked-children="$t('airconditiondDevice.a24')"
        :un-checked-children="$t('airconditiondDevice.a25')"
        @click="switchStatusChangeEvent"
        />
      <!-- <div class="switch-actions">
        <div class="actions-wrap" @click.stop="lightSettingsEvent">
          <a-icon class="actions-icon" type="clock-circle"/>
          <span class="actions-text">场景</span>
        </div>
      </div> -->
    </div>

    <div class="v-btn">
      <!-- <a-button  type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(1003)"/> -->
      <a-button v-if="cardContent.cameraId1>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId1)"/>
      <a-button v-if="cardContent.cameraId2>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId2)"/>
    </div >
  </div>
</template>

<script>
import {lightSwitchSetting} from '@/api/device';
export default {
  name: "lightCard",
  props: {
    siteId:String,
    cardContent: {
      type: Object,
      default: () => ({
        switchStatusValue: false
      })
    },
  },
  data() {
    return {
      switchStatusValue: this.cardContent.switchStatusValue,
      switchStatusLoading: false
    }
  },
  watch: {
    cardContent: {
      handler: function(valueData) {
        if (this.cardContent.id == valueData.id) {
          this.switchStatusValue = valueData.switchStatusValue;
        }
      },
      deep: true
    }
  },
  mounted() {
    console.log('switchStatusValue:', this.switchStatusValue);
  },
  methods: {
    lightSettingsEvent() {
      this.$emit('lightModelSettingsEvent', this.cardContent);
    },
    switchStatusChangeEvent(value) {
      let params = {};
      const itemData = this.cardContent;
      const boMapOptions = this.cardContent.boMap;
      const findItem = boMapOptions.find(items => Object.keys(items).includes('BO_OutVal') ? items : null);
      const switchStatusOptions = {true: 'on', false: 'off'};
      if (switchStatusOptions[value]) {
        params.rule = switchStatusOptions[value];
      }
      if (findItem.SignalPID) {
        params.signalId = findItem.SignalPID;
      }
      if (itemData.id) {
        params.pointId = itemData.id;
      }
      console.log('params:', params);
      this.switchStatusLoading = true;
      lightSwitchSetting(params).then((res) => {
        const { status } = res;
        this.switchStatusLoading = false;
        if (status === 200) {
          // 返回成功才更新状态
          this.$message.info(this.$t('lightDetails.d14'));
        } else {
          itemData.switchStatus = false;
          this.$message.error(this.$t('lightDetails.d15'));
        }
        // 刷新列表
        setTimeout(() => this.$emit('refreshListEvent'), 50);
      }).catch((err) => {
        this.switchStatusLoading = false;
        this.$message.error(this.$t('lightDetails.d15') + err);
      });
    },
    getVideo(cameraId) {
      const data = {
        vdialog: true,
        cameraId: cameraId,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          cameraId: cameraId,
        }
      }
      console.log('getVideo>>>',data);
      this.$emit('hasVideoChange', data );
    },
    goDetails() {
      console.log("godetails cardContent:",this.cardContent);
      let analogPoints = "";
      if(this.cardContent.aoMap!=null&&this.cardContent.aoMap.length>0){
        for(let i=0;i<this.cardContent.aoMap.length;i++){
          if(analogPoints=="")
            analogPoints = this.cardContent.aoMap[i].SignalPID;
          else
            analogPoints = analogPoints +","+this.cardContent.aoMap[i].SignalPID;
        }
      }
      let digitalPoints = "";
      if(this.cardContent.boMap!=null&&this.cardContent.boMap.length>0){
        for(let i=0;i<this.cardContent.boMap.length;i++){
          if(digitalPoints=="")
            digitalPoints = this.cardContent.boMap[i].SignalPID;
          else
            digitalPoints = digitalPoints +","+this.cardContent.boMap[i].SignalPID;
        }
      }


      this.$router.push({
          path: "/homepage/light/lightDetails",
          query: {
            id:this.cardContent.id,
            siteId: this.siteId,
            lightName: this.cardContent.name,
            analogPoints: analogPoints,
            digitalPoints: digitalPoints,
            deviceType:this.cardContent.type,
          },
      });
    },
  },
  computed:{
    disabled:function(){
      // 这里type:300代表灯光照明
      if(this.cardContent.type == 300){
        return false
      }else{
        return true
      }
    },
    state:function(){
      // 通过BO_InVal字段判断修改switch状态
      const { boMap = []} = this.cardContent || {};
      console.log('this.cardContent:', this.cardContent);
      const findItem = boMap.find(items => Object.keys(items).includes('BO_InVal') ? items : null);
      console.log('findItem:', findItem);
      if (findItem.BO_InVal == 1) {
        return true;
      } else {
        return false;
      }

      // if(this.cardContent.BO_InVal==1){
      //   return true
      // }else{
      //   return false
      // }
    },
    aoInVal:function(){
      if(this.cardContent!=null&&this.cardContent.aoMap!=null){
        for(let i=0;i<this.cardContent.aoMap.length;i++){
          if(this.cardContent.aoMap[i].SignalNo==45){
            return this.cardContent.aoMap[i].AO_InVal;
          }
        }
      }else{
        return "0.00cd";
      }
    }
  },
  mounted() {
    // console.log("传参内容"+this.siteId,this.cardContent);
  },
};
</script>

<style scoped>
#lightCard {
  width: 200px;
  height: 150px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  transition: 200ms;
}
#lightCard:hover {
  border: 1px solid #7682ce;
}
.title{
  width:100%;
  height: 40%;
  font-size: 13px;
}
.AO_InVal{
  width:100%;
  height: 40%;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 30%;
  font-size: 24px;
}
.switch{
  display: flex;
  width:100%;
  height: 20%;
  padding: 0 10px;
}
.switch-actions {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 21px;
  user-select: none;
  cursor: pointer;
  align-items: center;
  overflow: hidden;
}
.switch-actions .actions-wrap {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #007aff;
  user-select: none;
  cursor: pointer;
}
.switch-actions .actions-wrap:hover {
  text-decoration: underline;
}
.switch-actions .actions-icon {
  font-size: 14px;
}
.switch-actions .actions-text {
  font-size: 14px;
}
.v-btn{
	  position: absolute;
	  right: 2%;
	  bottom: 1%;
	  display: grid;
	}
</style>