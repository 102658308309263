import { render, staticRenderFns } from "./sceneLightSelectDialog.vue?vue&type=template&id=bafa2d1a&scoped=true"
import script from "./sceneLightSelectDialog.vue?vue&type=script&lang=js"
export * from "./sceneLightSelectDialog.vue?vue&type=script&lang=js"
import style0 from "./sceneLightSelectDialog.vue?vue&type=style&index=0&id=bafa2d1a&prod&scoped=true&lang=css"
import style1 from "./sceneLightSelectDialog.vue?vue&type=style&index=1&id=bafa2d1a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bafa2d1a",
  null
  
)

export default component.exports