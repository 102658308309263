<template>
  <div id="equipment">
    <a-spin :spinning="spinning" class="maina">
      <div class="header">
        <a-form-model :model="form" layout="inline">
          <a-form-model-item :label="$t('doorequipment.a2')">
            <a-select v-model="form.region" style="width: 120px">
              <a-select-option v-for="(item, value) in options" :key="value">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="actions">
          <div class="actions-wrap" @click.stop="setSceneTableListDialogEvent">
            <a-icon class="actions-icon" type="clock-circle"/>
            <span class="actions-text">总场景设置</span>
          </div>
        </div>
      </div>
      <div class="main">
        <my-lightCard v-for="(item,value) in lightDataList"
            :key="value"
            :siteId="siteId"
            :cardContent="item"
            @refreshListEvent="getCardList"
            @hasVideoChange="hasVideoChange"></my-lightCard>
        <div class="empty" v-for="n in 10" :key="'m'+n"></div>
      </div>
    </a-spin>
    <div class="footer">
      <ul class="express">
        <li class="expressli" v-for="(item,index) in icon" :key="index">
          <img :src="item.url" />
          <span>{{ $t(item.name) }}</span>
        </li>
      </ul>
    </div>
    <!-- 视频弹框 -->
    <camera-dialog :cameraId="cameraId" :vmode="vmode" :starDate="starDate.format('YYYY/MM/DD HH:mm:ss')" :endDate="endDate.format('YYYY/MM/DD HH:mm:ss')"></camera-dialog>

    <!-- 总场景设置 -->
    <my-sceneTable-dialog ref="sceneTableDialogRefs"/>
  </div>
</template>

<script>
import { getTenantList } from "../../api/door";
import lightCard from "./components/lightCard";
import { getCardList } from "../../api/device";
import cameraDialog from '../monitoring/cameraDialog.vue';
import sceneTableDialog from './components/sceneTableDialog.vue';
import moment from "moment";
import { cloneDeep } from "lodash";
export default {
  data() {
    return {
      form: {
        region: 0,
        region1: 0,
      },
      spinning:false,
      options: [],
      lightList: [],
      siteId: this.$route.query.id,
      typeselect: [{ value: "全部" }, { value: "门" }, { value: "认证终端" }],
      icon: [
        {
          name: "doorequipment.a6",
          url: require("../../../public/images/door/equipment01.png"),
        },
        {
          name: "doorequipment.a7",
          url: require("../../../public/images/door/equipment02.png"),
        },
        {
          name: "doorequipment.a8",
          url: require("../../../public/images/door/equipment03.png"),
        },
        {
          name: "doorequipment.a9",
          url: require("../../../public/images/door/equipment04.png"),
        },
        {
          name: "doorequipment.a10",
          url: require("../../../public/images/door/equipment05.png"),
        },
        {
          name: "doorequipment.a11",
          url: require("../../../public/images/door/equipment06.png"),
        },
      ],
      cameraId:0,
      vdialog: false,
      vmode:'1',
      starDate:new moment().startOf('day'),
      endDate:new moment(),
      deviceItemId: null
    };
  },
  computed: {
    lightDataList() {
      const data = [...this.lightList];
      data.forEach(items => {
        const boMapOptions = items.boMap || [];
        const findItem = boMapOptions.find(items => Object.keys(items).includes('BO_InVal') ? items : null);
        items.switchStatusValue = findItem.BO_InVal == 1 ? true : false;
      });
      return data;
    }
  },
  async created() {
    this.spinning = true;
    await getTenantList(this.$route.query.id)
      .then((res) => {
        // console.log(res);
        let { data } = res;
        for (let i = data.length - 1; i > -1; i--) {
          this.options.push({ value: data[i].id, name: data[i].name });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.getCardList(this.options[0].value);
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        this.spinning = true;
        console.log(this.options[newVal.region].value);
        this.getCardList(this.options[newVal.region].value);
      },
      deep: true,
    },
  },
  methods: {
    getCardList(tenantId){
      this.spinning = true;
      let par = {
        siteId: this.$route.query.id,
        tenantId: tenantId,
        deviceType: 300,
        deviceKind: 2,
      };
      console.log("light listDetail par:", par);
      getCardList(par)
      .then((res) => {
        console.log("light listDetail res:", res);
        let { data } = res;
        this.lightList = data;
        this.spinning = false;
      })
      .catch((err) => {
        console.log("light listDetail err:", err);
        this.spinning = false;
      });
    },
    onSubmit() {
      console.log("submit!", this.form);
    },
    login() {
      this.$router.push("/DoorDetails");
    },
    moment,
    // 卡片视频按钮点击回调函数
    hasVideoChange(data){
      this.vdialog = true;
      this.cameraId = data.cameraId;
    },

    // 场景模式设置
    setSceneTableListDialogEvent() {
      const sceneTableDialogRefs = this.$refs.sceneTableDialogRefs;
      sceneTableDialogRefs.dialogShow();
    }
  },
  components: {
    "my-lightCard": lightCard,
    "camera-dialog":cameraDialog,
    "my-sceneTable-dialog": sceneTableDialog
  },
};
</script>

<style scoped>
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
#equipment {
  height: 100%;
}
.header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  box-shadow: 0px 3px 8px #e7e6e6;
  overflow: hidden;
}
.express {
  display: flex;
}
.expressli {
  display: flex;
  align-items: center;
  margin-right: 20px;
  white-space: nowrap;
}
.expressli img {
  margin-right: 5px;
}
.main {
  width: 100%;
  height: calc(100% - 90px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 20px 20px 20px;
  overflow: auto;
}
.maina{
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
}
.empty {
  width: 200px;
  height: 1px;
  margin: 0 auto;
}
.content-ul {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.content-li {
  width: 220px;
  height: 114px;
  padding: 10px 0 20px 20px;
  background: #f3f3f7;
  color: #898989;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px auto;
  border-radius: 5px 5px 5px 5px;
  transition: 200ms;
}
.content-li:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.footer {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
</style>


<style lang="less" scoped>
.actions {
  height: 26px;
  .actions-wrap {
    display: flex;
    align-items: center;
    gap: 3px;
    height: 26px;
    color: #007aff;
    user-select: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    .actions-icon {
      font-size: 14px;
    }
    .actions-text {
      font-size: 14px;
    }
  }
}
</style>